import { CustomArrowProps } from 'react-slick';
import cn from 'shared/sections/AboutDevelopment/style.module.sass';

export const SlickArrowRight = (props: CustomArrowProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { currentSlide, slideCount, onClick } = props;

    return (
        <button
            id={cn['arrow-right']}
            disabled={currentSlide === slideCount ?? 0 - 1}
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
            onClick={onClick}
            {...props}
        >
            <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1.3999 1.5L7.2499 7.35L1.3999 13.2" stroke="#D4DAE5" strokeWidth="2" />
            </svg>
        </button>
    );
};
export default SlickArrowRight;
