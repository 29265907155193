'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';
import { useInView } from 'react-cool-inview';

import cn from './style.module.sass';

type ScrollAnimationProps = {
    children: ReactNode;
    isSlideUp?: boolean;
    className?: string;
    animateIn?: string;
    offset?: number;
    animateOnce?: boolean;
    duration?: number;
    delay?: number;
};

export default function ScrollAnimation({ children, isSlideUp, className }: ScrollAnimationProps) {
    const { observe, inView } = useInView<HTMLDivElement>({
        unobserveOnEnter: true,
        rootMargin: '-100px 0px',
    });
    let visible = cn.visible;

    if (isSlideUp) {
        visible = cn.slideUp;
    }
    const animate = inView ? visible : cn.hidden;

    return (
        <div ref={observe} className={clsx(cn.wrapper, className, animate)}>
            {children}
        </div>
    );
}
