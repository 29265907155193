import clsx from 'clsx';
import React, { ReactNode } from 'react';

import cn from './style.module.sass';

type FullWrapperProps = {
    children?: ReactNode;
    className?: string;
    smallPaddings?: boolean;
    isMargin?: boolean;
    bigPaddings?: boolean;
};

export default function FullWrapper({
    children,
    bigPaddings = false,
    smallPaddings = false,
    className = '',
    isMargin = true,
}: FullWrapperProps) {
    let isMarginStr = '';
    if (!isMargin) {
        isMarginStr = cn['case__item--is-marginless'] as string;
    }

    let isSmallPaddingsStr = '';
    if (smallPaddings && !bigPaddings) {
        isSmallPaddingsStr = cn['case__item--small-paddings'] as string;
    }

    let isBigPaddingsStr = '';
    if (bigPaddings && !smallPaddings) {
        isBigPaddingsStr = cn['case__item--big-paddings'] as string;
    }

    return (
        <div
            className={clsx(
                `${cn['case__item']} ${className} ${isSmallPaddingsStr} ${isMarginStr} ${isBigPaddingsStr}`
            )}
        >
            <div className={cn['full-wrapper']}>{children}</div>
        </div>
    );
}
