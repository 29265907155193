'use client';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css';

import FullWrapper from 'core/FullWrapper';
import ScrollAnimation from 'core/ScrollAnimation';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
import { useMedia } from 'react-use';
import SlickArrowLeft from 'shared/sections/AboutDevelopment/Arrows/SlickArrowLeft';
import SlickArrowRight from 'shared/sections/AboutDevelopment/Arrows/SlickArrowRight';
import { listAboutSlider } from 'shared/sections/AboutDevelopment/constants';
import Slide from 'shared/sections/AboutDevelopment/Slide/Slide';

import cn from './style.module.sass';

const AboutDevelopment = () => {
    const isMobile = useMedia('(max-width: 1199px)');

    const [isMount, setIsMount] = useState(false);
    useEffect(() => {
        setIsMount(true);
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    let slider: unknown;

    const settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <SlickArrowRight />,
            prevArrow: <SlickArrowLeft />,
            variableWidth: !isMobile,
        }),
        [isMobile]
    );
    return (
        <ScrollAnimation>
            <FullWrapper smallPaddings={true} isMargin={false} className={cn['about-development']}>
                <div className={cn['about-developmentHeader']}>Клиенты о нас</div>
                <div className={cn['about-developmentContainer']}>
                    {isMount && (
                        <Slider
                            /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
                            ref={c => (slider = c)}
                            className={cn['about-developmentSlider']}
                            {...settings}
                        >
                            {listAboutSlider.map(({ icon, title, author, position }) => {
                                return (
                                    <Slide
                                        key={title}
                                        title={title}
                                        icon={icon}
                                        author={author}
                                        position={position}
                                    />
                                );
                            })}
                        </Slider>
                    )}
                </div>
            </FullWrapper>
        </ScrollAnimation>
    );
};

export default AboutDevelopment;
