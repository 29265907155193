import clsx from 'clsx';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import Image, { ImageProps } from 'next/image';

import cn from './style.module.sass';

type BasicImageProps = ImageProps & {
    className?: string;
    customObjectFit?: 'cover' | 'contain';
    loading?: string;
    priority?: boolean;
};

export default function BasicImage({
    className,
    alt,
    customObjectFit,
    src,
    height,
    width,
    sizes,
    loading,
    priority,
    ...props
}: BasicImageProps) {
    const imageSrc = getExternalLink(src as string);

    return (
        <Image
            sizes={sizes}
            src={imageSrc}
            alt={alt}
            width={width}
            height={height}
            loading={loading ? loading : 'lazy'}
            priority={priority}
            className={clsx(
                className,
                customObjectFit === 'cover' && cn.objectFitCover,
                customObjectFit === 'contain' && cn.objectFitContain
            )}
            {...props}
        />
    );
}
