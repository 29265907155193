import BasicImage from 'core/BasicImage';
import React from 'react';
import cn from 'shared/sections/AboutDevelopment/style.module.sass';

type SlidePropsType = {
    title: string;
    author: string;
    position: string;
    icon: string;
    key: string;
};

const Slide = ({ title, author, icon, position, key }: SlidePropsType) => {
    return (
        <div key={key} className={cn['about-developmentItem']}>
            <div className={cn['about-developmentTitle']}>
                <span className={cn['about-developmentTitleSpan']}>«</span>
                {title}
                <span>»</span>
            </div>

            <div className={cn['about-developmentInner']}>
                <div className={cn['about-developmentInfo']}>
                    <div className={cn['about-developmentAuthor']}>{author}</div>
                    <div className={cn['about-developmentPosition']}>{position}</div>
                </div>
                <BasicImage
                    width={148}
                    height={57}
                    src={icon}
                    alt="icon"
                    className={cn['about-developmentIcon']}
                />
            </div>
        </div>
    );
};

export default Slide;
